var config = {
  // Set the Demo Version
  demo: false,

  // googleAddsense
  // googleAddsense: "",

  //SEO Configurations
  appName: "Eformula",
  metaDescription: "Eformula is a Web Quiz Application",
  metaKeyWords: "Eformula,Quiz,Questions,Answers,Online Quiz",
  //API Configurations
  apiAccessKey: 8525,
  apiUrl: "https://admin.eformula.site/api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "ke", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  // matchrandomBattleSeconds: 30,
  battlecorrectanswer: 4,
  randomBattleSeconds: 30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds

  //Firebase Configurations

  apiKey: "AIzaSyChgVVvZ3GHV_Ms-rNafeSrlCL0SIjjDJk",
  authDomain: "eformulae-bf8cf.firebaseapp.com",
  databaseURL: "https://admin.eformula.site",
  projectId: "eformulae-bf8cf",
  storageBucket: "eformulae-bf8cf.appspot.com",
  messagingSenderId: "820529154357",
  appId: "1:820529154357:web:c61e695f98326cf8dba47c",
  measurementId: "G-DTLQJ93TDW",

  //footer area
  companytext:
    "Eformula made with key principles of a beautiful, effective, simple to use and better code quality with use of functional based component.",
  addresstext: "Address: #262-263, Time Square Empire,",
  phonenumber: "",
  email: "info@georgethedeveloper.com",
  facebooklink: "https://www.facebook.com/mindberzerkk",
  instagramlink: "https://www.instagram.com/mindberzerk",
  linkedinlink: "https://www.linkedin.com/",
  weblink: "https://eformula.site",
  companyname: "Mindberzerk",
};

export default config;
